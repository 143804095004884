<template>
  <div class="fixd" id="wd">
    <div style=" width: 100%" class="mys">
      <div class="myimg">
        <div class="someimg">
          <img
            src="../assets/img/错误.png"
            alt="..."
            class="img-responsive img-circle"
          />
        </div>
        <div class="auther">博主:林</div>
        <div class="mymessages">
          <div class="QQimg" v-show="shows">
            <img class="img-responsive" :src="src" alt="" />
          </div>
          <img
            src="../assets/img/QQ图标.png"
            alt=""
            class="img-responsive"
            @mouseover="defult ? mouseOver() : null"
            @mouseleave="defult ? mouseLeave() : null"
            @click="xinxi"
          />
          <img
            src="../assets/img/微信图标(1).png"
            alt=""
            class="img-responsive"
            @mouseover="defult ? mouseOvers() : null"
            @mouseleave="defult ? mouseLeave() : null"
            @click="xinxi"
          />
        </div>
      </div>
      <div class="sss">
        <!-- <p>
         大学入学时还未从高三状态调整过来，所以很不适应突然放松下来，于是在偶然中学习前端知识，后来逐渐发现挺有意思，中间休息了大半学期，后面还是
         继续，学习了一些后端知识和数据库以后，在2021年暑假完成了这个博客，不知道未来是否能从事这个工作，但保持学习的热情，提升自己的能力，做自己喜欢的事总是没错的，这也给我
         带来一些成就感，让我的生活也多了一些乐趣。
        </p> -->
      </div>
    </div>
    <div class="indexLeft">
      <div class="leftBox">
        <times :isLogin="isLogin" />
      </div>
      <div class="leftTj">
        <h3 class="leftTjTitle"><p class="marginLeft">快捷链接</p></h3>
        <xlk :isLogin="isLogin"></xlk>
      </div>
    </div>
  </div>
</template>
<script>
import xlk from "./xlk.vue";
import times from "./time.vue";
export default {
  data() {
    return {
      Width: 0,
      pozitions: "",
      top: 0,
      src: require("../assets/img/1.jpg"),
      shows: false,
      defult: true,
    };
  },
  components: {
    xlk,
    times,
  },
  methods: {
    handleScroll() {
      let obj = document.getElementsByClassName("indexLeft")[0];
      // console.log(
      //   obj.offsetTop,
      //   document.documentElement.scrollTop,
      //   obj.offsetTop - document.documentElement.scrollTop
      // );
      if (obj.offsetTop - document.documentElement.scrollTop < 0) {
        this.pozitions = "fixed";
        this.top = "13%";
      } else if (
        document.documentElement.scrollTop < 300 &&
        obj.offsetTop < 300
      ) {
        this.pozitions = "relative";
        this.top = document.documentElement.scrollTop + "px";
      } else {
        this.pozitions = "relative";
        this.top = "1%";
      }
      // console.log(window.screen.availHeight);
    },
    WidthF(that) {
      if ((window.screenWidth = document.body.clientWidth > 800)) {
        let wd = document.getElementById("wd");
        // console.log(wd.scrollWidth);
        that.Width = wd.scrollWidth;
      } else {
        that.Width = 0;
      }
    },
    xinxi() {
      location.location.hash = "#sss";
    },
    mouseOver() {
      this.shows = true;
      this.src = require("../assets/img/2.png");
      var bodys = document.getElementsByClassName("QQimg")[0];
      $(bodys).css("animation-name", "toB");
    },
    mouseLeave() {
      this.shows = false;
      var bodys = document.getElementsByClassName("QQimg")[0];
      $(bodys).css("animation-name", "toL");
    },
    mouseOvers() {
      this.shows = true;
      this.src = require("../assets/img/1.jpg");
      var bodys = document.getElementsByClassName("QQimg")[0];
      $(bodys).css("animation-name", "toB");
    },
  },
  mounted() {
    const that = this;
    // console.log(this.data);
    // console.log("ssssssssssssssss");
    window.addEventListener("scroll", this.handleScroll);
    this.WidthF(that);
    var bodys = document.getElementById("wd");
    if (this.code == 1) {
      $(bodys).css("animation-name", "wdLeft");
    } else {
      $(bodys).css("animation-name", "wdRight");
    }
    window.onresize = () => {
      return (() => {
        this.WidthF(that);
      })();
    };
  },
  props: {
    code: Number,
    default: -1,
    isLogin: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  // props:{
  //   data:{
  //     type:Array,
  //     default:[]
  //   }
  // }
};
</script>
<style>
@media screen and (max-width: 980px) {
  .indexLeft {
    position: relative;
    width: 0 !important;
    height: 0;
    display: flex;
    flex-direction: column;
  }
}
.fixd {
  width: 100%;
  position: relative;
  margin-top: 0.38125rem;
}
#wd {
  width: 95%;
  transform: translateX(500px);
  /* animation-name: wdLeft; */
  transform: scale(0);
  animation-duration: 3s;
  transition-timing-function: linear;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
@keyframes wdLeft {
  0% {
    transform: translateX(500px) scale(0);

  }
  to {
    transform: translateX(0px) scale(1);
  }
}
@keyframes wdRight {
  0% {
    transform: translateX(-500px) scale(0);
  }
  to {
    transform: translateX(0px) scale(1);
  }
}
.indexLeft {
  /* position: fixed;
    top: 25%; */
  background-color: #fff;
}
.leftTjTitle {
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f2;
  display: flex;
  flex-direction: column;
  position: relative;
}
.leftTjTitle p {
  margin-bottom: 0;
}
/*包含以下五种的链接*/
/* .list-unstyled a{
    text-decoration: none;
    color: #222226;
}
.list-unstyled a:hover {
    text-decoration: none;
    color: red;
} */
.list-unstyled > li {
  /* height: 43px; */
  border-bottom: 1px solid #f0f0f2;
  display: flex;
  align-items: center;
}
.list-unstyled > li button {
  background: none;
  border: none;
}
.marginLeft {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.list-unstyled > li > div {
  font-size: 23px;
  text-align: left;
}
.leftBox {
  width: 100%;
  margin-bottom: 20px;
}
.leftTj {
  background-color: #fff;
}
.mys {
  background: #fdfafa;
}
.myimg {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60%;
}
.myimg > div {
  margin-top: 10px;
}
.someimg {
  width: 20%;
}
.auther {
  font-size: 22px;
  font-weight: 600;
}
.mymessages {
  width: 20%;
  display: flex;
  justify-content: space-between;
}
.mymessages > img {
  width: 30px;
  height: 30px;
}
.sss {
  margin-top: 5%;
  width: 80%;
  margin-left: 10%;
}
.QQimg {
  width: 100px;
  height: 100px;
  /* background-image: url('../assets/img/1.png'); */
  position: absolute;
  top: 190px;
  left: 145px;
  transform: scale(0);
  /* animation-name: toB; */
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
@keyframes toB {
  0% {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes tol {
  0% {
    transform: scale();
  }
  to {
    transform: scale(0);
  }
}
</style>